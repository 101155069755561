.upload {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.285);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-container {
  padding: 1rem;
  max-width: 550px;
  max-height: 550px;
}
.upload-overwiev {
  border-radius: 40px 80px;
  background-clip: content-box;
  min-height: 350px;
  min-width: 350px;
  max-width: 550px;
  max-height: 550px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: white;
  padding: 2.5rem;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(222, 222, 230, 0.25) 0px 50px 100px -20px,
    rgba(175, 156, 156, 0.3) 0px 30px 60px -30px;
  overflow: auto;
  border: rgb(146, 120, 120) 10px double;
}
.upload-header {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.score {
  font-weight: 700;
  margin-bottom: 1rem;
}
#submit-post {
  font-weight: 700;
}
#submit-post:hover {
  background: rgb(3, 112, 101);
  color: white;
}
@media screen and (max-width: 350px) {
  .upload-overwiev {
    min-width: 250px;
  }
  .upload-header {
    display: grid;
  }
}
