.main {
  height: 95vh;
  display: flex;
  width: 100%;
  justify-content: center;
}
.container {
  display: grid;
  margin: auto;
  width: 70%;
  place-items: center;
  min-height: 250px;
  background-color: white;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  padding: 0rem 1rem 1rem;
}

.auth_btn {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0.5rem;
}
.auth_btn button {
  font-size: 0.8rem;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-radius: 10px;
  border: 4px solid #d2e9f8;
  background-color: #1d974a;
  color: rgb(255, 255, 255);
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
}
.auth_btn button:hover {
  box-shadow: -1px 1px 26px 3px #9dd1f3;
  -webkit-box-shadow: -1px 1px 26px 3px #9dd1f3;
  -moz-box-shadow: -1px 1px 26px 3px #9dd1f3;
  background-color: #11642f;
}
.body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}
.body_text {
  margin-top: 3.5rem;
}
.body_text h1 {
  font-size: x-large;
  font-style: italic;
  background: radial-gradient(rgb(119, 252, 52), rgb(194, 49, 49)) fixed;
  background: conic-gradient(
      rgb(245, 166, 48),
      rgb(238, 45, 87),
      rgb(248, 182, 59)
    )
    fixed;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.body-img {
  width: 100%;
}
.body-img img {
  width: 100%;
}
.container::after {
  position: absolute;
  content: "";
  z-index: 121;
  width: 72%;
  min-height: 55%;
  z-index: -1;
  background: rgba(255, 255, 255, 0.349);
  transform: rotate(7deg);
  border-radius: 20px;
}
@media screen and (max-width: 1050px) {
  .body {
    grid-template-columns: 1fr;
    grid-row-gap: 3.5rem;
    place-items: center;
    width: 100%;
  }
  .body h1 {
    margin-top: 0.5rem;
    font-size: large;
  }
}
@media screen and (max-width: 750px) {
  .head {
    display: grid;
    place-items: center;
  }
}
