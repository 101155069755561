.page {
  width: 98%;
  border-radius: 20px;
  height: 100%;
  padding-bottom: 1.5rem;
  top: 1.5%;
  z-index: -3;
  background-color: rgba(211, 211, 211, 0.336);
  border: 1px solid white;
  margin: auto;
  user-select: none;
}
.overwiev {
  display: grid;
  width: 95%;
  place-items: center;
  margin: auto;
  margin-top: 2rem;
  padding: 1rem 1rem 3rem 1rem;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  z-index: 7;
}
.page_generator {
  width: 95%;
  display: grid;
  background-color: #d5eafc;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  z-index: 15;
}
.circles {
  position: fixed;
  width: 100%;
  height: 100%;
}
.circles li {
  position: absolute;
  list-style: none;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: rgba(190, 137, 137, 0.575);
  animation: animate 25s linear infinite;
  bottom: -150px;
  z-index: -5;
  overflow: hidden;
}
.circles li:nth-child(1) {
  left: 0%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 0%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 0%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  right: 2%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  right: 6%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  right: 12%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}
.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}
.speeddial {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
}
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
@media screen and (max-width: 750px) {
  .overwiev {
    padding: 0;
  }
}
