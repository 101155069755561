.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  gap: 1.5rem;
  z-index: 44;
}
.toglemenu {
  display: none;
}

.logo img {
  width: 60px;
  cursor: pointer;
  margin-left: 2rem;
}
.component {
  display: flex;
  justify-content: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.component div {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.user {
  margin-right: 2.5rem;
  cursor: pointer;
}
.backdrop {
  z-index: 1000;
}
.usershow {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
}
.usershow img {
  width: 40px;
  border-radius: 50%;
}
.settings {
  display: none;
  position: fixed;
  z-index: 25;
  transition: transform 0.15s;
  transition-delay: 0.925s;
  z-index: 1000;
}

.settings div {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 1rem;
  z-index: 1000;
  border-radius: 20px;
}
.mobilemenu {
  display: none;
  z-index: 25;
  position: fixed;
  width: 70%;
  margin: auto;
  top: 9rem;
  z-index: 1001;
}
.mobilemenu div {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
@media screen and (max-width: 1050px) {
  .component {
    width: fit-content;
  }
  .component div {
    gap: 0.5rem;
  }
  .component div .component-text {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .navbar {
    gap: 1.5rem;
  }
  .component {
    display: none;
  }
  .usershow img {
    flex-direction: 2;
  }
  .username {
    flex-direction: 1;
  }
  .logo img {
    margin-left: 0.5rem;
  }
  .toglemenu {
    display: block;
    font-size: x-large;
    margin-left: 0.5rem;
  }
}
